<template>
  <div class="header_view" :style="props.borderShow ? 'height: 1.4rem;:' : 'height: 1.13rem;'" v-show="data.titleshow">
    <div class="header_title" :class="props.fixed ? 'title_fixed' : ''">
      <div class="return" v-if="props.return" @click="back()">
        <img class="" src="@/assets/images/return.png" alt="">
      </div>
      {{ props.titleName }}
    </div>
    <div style="height: 1.1333rem;" v-if="props.fixed"></div>
    <div v-if="props.borderShow" style="height: .26rem;background-color: #f5f5f5;"></div>
  </div>

</template>

<script setup>
import { defineProps, reactive, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router'
const props = defineProps({
  titleName: String,
  return: Boolean,
  borderShow: Boolean,
  fixed: Boolean,
});
let data = reactive({
  titleshow:true,
});

onMounted(() => {


  let userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('qzapp') !== -1||userAgent.indexOf('micromessenger') !== -1) {
    data.titleshow = false
    document.title = props.titleName
  }

});
const router = useRouter()
const back = () => {
  console.log()

  // if(router.options.history.state.back.includes('find')&&router.currentRoute.value.path=='/findApplyMsg'){
  //   sessionStorage.setItem('searchKeyword', JSON.stringify({ tab: '1', fundcode: '110011' }))

  //   return
  // }
  if (router.options.history.state.back.includes('applyMessage')) {
    router.go(-2)
    return
  }
  router.go(-1)
}

</script>


<style lang='less' scoped>
.title_fixed {
  position: fixed;
  left: 0;
  top: 0;
}

.header_view {
  position: relative;
  background-color: #fff;
}

.header_title {
  height: 1.1333rem;
  width: 100%;
  background-color: #fff;
  font-size: .44rem;
  text-align: center;
  padding-top: .3333rem;
  box-sizing: border-box;

  z-index: 7;

  .return {
    width: 1.2rem;
    height: 1.1333rem;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: .4rem;
    display: flex;
    align-items: center;

    img {
      width: .2667rem;
    }
  }
}
</style>